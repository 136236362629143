import * as React from "react";
import { Layout } from "../components/global/Layout";
import { Home } from "../components/home/Home";
import SEO from "../components/global/SEO";
import { graphql } from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Home />
    </Layout>
  );
};

export default IndexPage;
