import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Title, ReadMore } from "../../reusables/Mixed";

const ServiceCard = ({
  homepageTitle,
  shortDescription,
  backgroundImageUrl,
}) => {
  return (
    <div className="shadow-xl rounded overflow-hidden w-full">
      <div>
        <div
          style={{
            backgroundImage: `url('${backgroundImageUrl}')`,
          }}
          className="h-28 flex items-center bg-cover bg-center"
        >
          <div className="flex justify-center items-center w-full uppercase text-white font-medium bg-[#000000]/75 tracking-wide text-2xl py-2">
            <span>{homepageTitle}</span>
          </div>
        </div>
      </div>
      <div className="px-4 py-5 text-center">
        <p className="mb-4 w-full text-sm sm:text-base md:text-lg">
          {shortDescription}
        </p>
        {/* <ReadMore to="/" /> */}
      </div>
    </div>
  );
};

export const Services = () => {
  const data = useStaticQuery(graphql`
    query HomepageServicesQuery {
      allStrapiService {
        edges {
          node {
            data {
              attributes {
                weight
                test
                shortDescription
                name
                homepageTitle
                displayOnHome
                description
                backgroundImageUrl
              }
            }
          }
        }
      }
    }
  `);

  const services = data?.allStrapiService?.edges[0]?.node?.data
    ?.map((x) => x.attributes)
    .filter((x) => x.displayOnHome)
    .sort((a, b) => a.weight - b.weight);

  if (!services) return null;

  return (
    <section className="mt-10 lg:mt-20 mx-6 lg:mx-10">
      <Title>our services</Title>
      <div className="text-center">
        <h2 className="mb-5">How We'll Grow Your Business</h2>
        <h3 className="mb-5">
          Our approach is tailored to help you succeed in a competitive market.
        </h3>
      </div>
      <div className="flex flex-col mt-6 lg:mt-10 lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10">
        {services.map((service) => (
          <ServiceCard key={service.homepageTitle} {...service} />
        ))}
      </div>
    </section>
  );
};
