import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { CtaButtonText } from "../../reusables/Mixed";
import mainImg from "../../images/above_fold_img.svg";

export const AboveFold = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiHomePage {
        edges {
          node {
            data {
              attributes {
                ctaMainMessage
                ctaSecondaryMessage
              }
            }
          }
        }
      }
    }
  `);

  const ctaContent = data?.allStrapiHomePage?.edges[0]?.node?.data?.attributes;

  if (!ctaContent) return null;

  return (
    <section className="mx-6 flex justify-center mt-5 lg:mt-10">
      <div className="flex lg:w-10/12 flex-col lg:flex-row">
        <div className="flex flex-col items-start justify-center lg:w-1/2 lg:pr-7 xl:pr-16 2xl:pr-20">
          <span className="font-bold text-primary mb-5 text-2xl text-center w-full lg:w-fit lg:text-left xl:mb-10 lg:text-3xl xl:text-5xl 2xl:text-6xl ">
            {ctaContent?.ctaMainMessage}
          </span>
          <h1 className="font-medium  mb-5  text-center w-full lg:w-fit lg:text-left xl:mb-10 text-base lg:text-lg xl:text-2xl 2xl:text-3xl">
            {ctaContent?.ctaSecondaryMessage}
          </h1>
          <Link
            to="/get-started"
            className="btn btn-lg self-center lg:self-start md:btn-xl xl:btn-2xl"
          >
            <CtaButtonText />
          </Link>
        </div>
        <div className="hidden lg:w-1/2 lg:flex items-center justify-center mt-10 lg:mt-0">
          <img src={mainImg} alt="mainImg" />
        </div>
      </div>
    </section>
  );
};

// {/* <span className="font-bold   mt-10 w-full text-center lg:text-left lg:w-fit lg:mt-0 pt-5 lg:py-20 xl:text-3xl lg:pl-7 xl:pl-16 2xl:pl-20 2xl:text-4xl"> */}
// {/* <span className="font-bold  border-primary border-t-2 mt-10 w-full text-center lg:text-left lg:w-fit lg:mt-0 pt-5 lg:py-20 lg:border-t-0 lg:border-l-2 xl:text-3xl lg:pl-7 xl:pl-16 2xl:pl-20 2xl:text-4xl"> */}
// {/* <span className="mr-2">
//     You can focus on running your business while I focus on growing it
//   </span>
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     className="h-7 w-7 md:w-12 md:h-12 xl:h-16 xl:w-16 text-primary inline"
//     fill="none"
//     viewBox="0 0 24 24"
//     stroke="currentColor"
//   >
//     <path
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       strokeWidth={2}
//       d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
//     />
//   </svg> */}
// {/* </span> */}
