import * as React from "react";
import { Link } from "gatsby";

export const GetInTouch = () => {
  return (
    <section className="w-full mt-12 bg-primary lg:mt-20  flex flex-col  justify-center items-center px-3 py-7 lg:p-10 lg:flex-row">
      <span className="font-bold text-white text-xl mb-7 md:text-4xl lg:mb-0 lg:mr-10 xl:text-4xl">
        Ready for more customers?
      </span>
      <Link
        to="/get-started"
        className="btn btn-lg btn-outline-white mb-1 md:btn-xl lg:mb-0"
      >
        get in touch now
      </Link>
    </section>
  );
};
