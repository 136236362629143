import * as React from "react";
import { AboveFold } from "./AboveFold";
import { Speciality } from "./Speciality";
import { Services } from "./Services";
import { TopArticle } from "./TopArticle";
import { Testimonials } from "./Testimonials";
import { MyWork } from "./MyWork";
import { GetInTouch } from "./GetInTouch";

export const Home = () => {
  return (
    <div className="w-full">
      <AboveFold />
      <div className="container mx-auto">
        {/* <Speciality /> */}
        <Services />
        {/* <TopArticle /> */}
        {/* <Testimonials /> */}
        {/* <MyWork /> */}
      </div>
      <GetInTouch />
    </div>
  );
};
